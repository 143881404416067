<template>
  <div>
    <lightgallery class="grid lg:grid-cols-3 gap-8 pointer-children">
      <img src="../assets/images/06-arch-projekt/MUSA-1.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-2.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-3.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-4.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-5.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-6.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-7.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-8.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-9.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-10.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-11.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-12.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-13.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-14.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-15.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-16.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-17.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-18.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-19.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-20.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-21.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-22.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-23.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-24.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-25.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-26.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-27.jpg" alt="" rel="preload">
      <img src="../assets/images/06-arch-projekt/MUSA-28.jpg" alt="" rel="preload">
    </lightgallery>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Lightgallery from 'lightgallery/vue';

export default {
  name: 'ArchProjekt',
  components: {
    Lightgallery,
  },
  mounted() {
    window.scrollTo({ top: 0});
  },
}
</script>
